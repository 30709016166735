import React from 'react';
import { Row, Col } from 'antd';
import './IndustrySection.css';

const IndustrySection = ({ industries }) => {
    return (
        <div className="industry-section" style={{paddingTop:'60px'}}>
            <h1 style={{fontSize:'40px',fontWeight:'bold'}}>Industries We Serve</h1>
            <Row gutter={[16, 16]} style={{paddingTop:'30px'}}>
                {industries.map((industry, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={6}>
                        <div className="industry-card">
                            <img src={industry.img} alt={industry.title} />
                            <h3>{industry.title}</h3>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default IndustrySection;

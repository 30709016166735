import React from 'react';
import { Row, Col, Card, Typography,Divider } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles
import BannerComponent from '../components/BannerComponent';
import ecommerce from '../assect/images/ecommerce.jpeg';
import elearning from '../assect/images/elearning.jpeg';

const { Title, Paragraph } = Typography;
const bannerImages = [
    { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
];
const pageHeadrImages = [
    { url: ecommerce, title: '24/7 Security Monitoring: Your Digital Shield, Anytime, Anywhere', subtitle: 'Unmatched Security at Your Fingertips' }
];
const SecurityMonitoring = () => {
    return (
        <div className="service-page">
           
            <BannerComponent images={pageHeadrImages} />
            <div className="content-section">
                <Row gutter={[16, 16]} className="even-row " style={ {display: 'flex',
        alignItems: 'center',}}>
                    <Col xs={24} sm={24} md={12} lg={16}>
                        <Paragraph className="main-paragraph">
                            In an increasingly digital world, the need for constant security has never been greater. At vaptix, we provide comprehensive 24/7 security monitoring services for websites and mobile apps, ensuring your digital assets are protected around the clock. Our advanced technology and dedicated team offer robust surveillance solutions that give you peace of mind.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div style={{float: 'right'}}><img src={elearning} alt="Security Monitoring" className="responsive-img" style={{ width: '80%', height: 'auto' }} /></div>
                        
                    </Col>
                </Row>
                <Row 
    gutter={[32, 32]} 
    style={{
        backgroundColor: '#EEEEEE',
        padding: '60px',
        paddingBottom: '60px',
        display: 'flex',
        alignItems: 'center',  // Center vertically

        minHeight: '100vh'  // Make sure it takes the full screen height
    }}
>
    <Col span={12}>
        <Title level={2} className="section-title" >Why Choose Our 24/7 Security Monitoring?</Title>
        <Divider className="section-divider"></Divider>
        
    </Col>
    <Col span={12}>
        <Col span={24}>
            <Card>
                <ul className="feature-list">
                    <li><RightCircleTwoTone /> <strong>Constant Vigilance:</strong> Our monitoring systems work tirelessly to detect and respond to potential threats in real-time, 24/7.</li>
                    <li><RightCircleTwoTone /> <strong>Immediate Response:</strong> Our security experts are on standby to address any security breaches instantly, minimizing risks and damage.</li>
                    <li><RightCircleTwoTone /> <strong>Advanced Technology:</strong> We leverage cutting-edge technologies, including AI and machine learning, to enhance threat detection and reduce false positives.</li>
                    <li><RightCircleTwoTone /> <strong>Remote Access:</strong> Manage and monitor your security from anywhere through our intuitive mobile app and web portal.</li>
                    <li><RightCircleTwoTone /> <strong>Tailored Solutions:</strong> We offer customized security plans to meet the unique needs of your website or mobile app, ensuring comprehensive protection.</li>
                </ul>
            </Card>
        </Col>
        
    </Col>
</Row>

                <Row gutter={[32, 32]}
                style={{
                    padding: '60px',
                    paddingBottom: '60px',
                    display: 'flex',
                    alignItems: 'center',
            
                    minHeight: '100vh'  // Make sure it takes the full screen height
                }} className="even-row">
                    <Col span={24}>
                        <Title level={2}   className="section-title main-title">Key Features of Our 24/7 Security Monitoring</Title>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Real-Time Alerts</Title>
                                    <Paragraph className="card-paragraph">Stay informed with instant notifications about any suspicious activities. Our system ensures you are always updated, whether you're at the office or on the go.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Expert Surveillance Team</Title>
                                    <Paragraph className="card-paragraph">Our team of security professionals monitors your digital assets around the clock. With years of experience, they can quickly identify and respond to potential threats, ensuring the highest level of protection.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Integrated Systems</Title>
                                    <Paragraph className="card-paragraph">We provide an integrated security solution that combines threat detection, vulnerability management, and access control. This multi-layered approach ensures robust protection for your website and mobile app.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Seamless Integration</Title>
                                    <Paragraph className="card-paragraph">Our monitoring systems seamlessly integrate with your existing digital infrastructure, providing a cohesive security experience without disrupting your operations.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <BannerComponent images={bannerImages} />
                <Row gutter={[32, 32]}
                style={{
                    padding: '60px',
                    paddingBottom: '60px',
                    display: 'flex',
                    alignItems: 'center',
            
                    minHeight: '100vh'  // Make sure it takes the full screen height
                }} className="even-row">
                    <Col span={24}>
                        <Title level={2} className="section-title main-title">Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]} >
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Peace of Mind</Title>
                                    <Paragraph className="card-paragraph">Rest easy knowing your digital assets are under constant watch, allowing you to focus on your core business.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Cost-Effective</Title>
                                    <Paragraph className="card-paragraph">Prevent potential losses and downtime with our proactive security measures, saving you money in the long run.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Safety</Title>
                                    <Paragraph className="card-paragraph">Our rapid response and advanced technology significantly enhance the security of your digital environment.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Support:</Title>
                                    <Paragraph className="card-paragraph">Our customer service team is available 24/7 to assist you with any queries or issues, ensuring a seamless security experience.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default SecurityMonitoring;

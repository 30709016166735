import React from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles
import BannerComponent from '../components/BannerComponent';
import dataAnalyticsAIImage from '../assect/images/elearning.jpeg'; // Update path to your data analytics & AI image

const { Title, Paragraph } = Typography;

const bannerImages = [
    { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
];

const pageHeaderImages = [
    { url: dataAnalyticsAIImage, title: 'Transform Your Business with Data Analytics & AI Solutions', subtitle: 'Unlock Insights and Drive Innovation' }
];

const DataAnalyticsAIPage = () => {
    return (
        <div className="service-page">
            <BannerComponent images={pageHeaderImages} />

            <div className="content-section">
                <Row gutter={[16, 16]} className="even-row " style={ {display: 'flex',
        alignItems: 'center',}}>
                    <Col xs={24} sm={24} md={16} lg={16}>
                        <Paragraph className="main-paragraph">
                            In today's data-driven world, leveraging advanced analytics and artificial intelligence is crucial for gaining a competitive edge. At Vaptix, we offer comprehensive data analytics and AI solutions to help you uncover actionable insights, optimize operations, and drive innovation. Our services include data visualization, predictive analytics, machine learning, and AI-driven decision support to transform your business data into valuable intelligence.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div style={{float: 'right'}}><img src={dataAnalyticsAIImage} alt="Data Analytics & AI Solutions" className="responsive-img" style={{ width: '80%', height: 'auto' }} /></div>
                        
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section" style={{ backgroundColor: '#EEEEEE', padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Title level={2} className="section-title">Why Choose Our Data Analytics & AI Solutions?</Title>
                        <Divider className="section-divider" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card>
                            <ul className="feature-list">
                                <li><RightCircleTwoTone /> <strong>Actionable Insights:</strong> Gain valuable insights from your data to make informed business decisions.</li>
                                <li><RightCircleTwoTone /> <strong>Predictive Analytics:</strong> Utilize predictive models to forecast trends and anticipate future outcomes.</li>
                                <li><RightCircleTwoTone /> <strong>AI-Driven Solutions:</strong> Implement AI technologies to automate processes and enhance decision-making.</li>
                                <li><RightCircleTwoTone /> <strong>Data Visualization:</strong> Visualize complex data in an intuitive way to easily understand patterns and trends.</li>
                                <li><RightCircleTwoTone /> <strong>Custom Solutions:</strong> Tailored analytics and AI solutions designed to meet your specific business needs and goals.</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Key Features of Our Data Analytics & AI Solutions</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Advanced Data Analytics</Title>
                                    <Paragraph className="card-paragraph">Leverage sophisticated analytics techniques to extract meaningful insights from your data.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Machine Learning Models</Title>
                                    <Paragraph className="card-paragraph">Develop and deploy machine learning models to enhance predictions and automate processes.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Data Visualization Tools</Title>
                                    <Paragraph className="card-paragraph">Create interactive dashboards and visualizations to simplify data interpretation.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Custom AI Solutions</Title>
                                    <Paragraph className="card-paragraph">Design and implement AI solutions tailored to your specific business challenges and objectives.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <BannerComponent images={bannerImages} />

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title" >Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={6} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Decision-Making</Title>
                                    <Paragraph className="card-paragraph">Make data-driven decisions with confidence, supported by our advanced analytics and AI solutions.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Increased Efficiency</Title>
                                    <Paragraph className="card-paragraph">Automate repetitive tasks and optimize processes to improve overall efficiency.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Scalable Solutions</Title>
                                    <Paragraph className="card-paragraph">Scalable solutions that grow with your business and adapt to changing needs.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default DataAnalyticsAIPage;

import React from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles
import BannerComponent from '../components/BannerComponent';
import complianceServicesImage from '../assect/images/elearning.jpeg'; // Update path to your compliance services image

const { Title, Paragraph } = Typography;

const bannerImages = [
    { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
];

const pageHeaderImages = [
    { url: complianceServicesImage, title: 'Expert Compliance Services: Ensure Regulatory Adherence', subtitle: 'Comprehensive Solutions for Your Compliance Needs' }
];

const ComplianceServicesPage = () => {
    return (
        <div className="service-page">
            <BannerComponent images={pageHeaderImages} />

            <div className="content-section">
                <Row gutter={[16, 16]} className="even-row" style={ {display: 'flex',
        alignItems: 'center',}}>
                    <Col xs={24} sm={24} md={16} lg={16}>
                        <Paragraph className="main-paragraph">
                            Navigating the complexities of regulatory requirements can be challenging for businesses. At Vaptix, we provide expert compliance services to ensure your organization meets all necessary regulatory standards and industry best practices. Our services include compliance audits, risk assessments, policy development, and ongoing support to help you maintain adherence to regulations and avoid potential penalties.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div style={{float: 'right'}}><img src={complianceServicesImage} alt="Compliance Services" className="responsive-img" style={{ width: '100%', height: 'auto' }} /></div>
                        
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section section-list" >
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Title level={2} className="section-title">Why Choose Our Compliance Services?</Title>
                        <Divider className="section-divider" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card>
                            <ul className="feature-list">
                                <li><RightCircleTwoTone /> <strong>Regulatory Expertise:</strong> Leverage our in-depth knowledge of industry regulations and compliance requirements.</li>
                                <li><RightCircleTwoTone /> <strong>Comprehensive Audits:</strong> Conduct thorough audits to assess your current compliance status and identify areas for improvement.</li>
                                <li><RightCircleTwoTone /> <strong>Risk Management:</strong> Develop and implement risk management strategies to mitigate compliance-related risks.</li>
                                <li><RightCircleTwoTone /> <strong>Policy Development:</strong> Create and update policies to ensure they align with current regulatory standards and best practices.</li>
                                <li><RightCircleTwoTone /> <strong>Ongoing Support:</strong> Receive continuous support and guidance to maintain compliance and address any emerging issues.</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Key Features of Our Compliance Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Expert Regulatory Guidance</Title>
                                    <Paragraph className="card-paragraph">Receive expert advice on navigating complex regulatory requirements and maintaining compliance.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Thorough Compliance Audits</Title>
                                    <Paragraph className="card-paragraph">Benefit from detailed audits to evaluate your current compliance status and uncover potential gaps.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Risk Assessment and Mitigation</Title>
                                    <Paragraph className="card-paragraph">Identify and address risks associated with compliance to reduce the likelihood of regulatory issues.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Policy Development and Updates</Title>
                                    <Paragraph className="card-paragraph">Create and revise policies to ensure they meet the latest regulatory requirements and industry standards.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <BannerComponent images={bannerImages} />

                <Row gutter={[16, 16]} className="feature-section " style={{ padding: '60px 20px', minHeight: '100vh' }} >
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title" >Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Compliance</Title>
                                    <Paragraph className="card-paragraph">Ensure your organization meets all regulatory requirements with our expert compliance services.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Risk Reduction</Title>
                                    <Paragraph className="card-paragraph">Mitigate compliance-related risks and avoid potential penalties with our proactive services.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Comprehensive Support</Title>
                                    <Paragraph className="card-paragraph">Receive continuous support and guidance to maintain compliance and address any emerging issues.</Paragraph>
                                </Card>
                            </Col>
                            
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ComplianceServicesPage;

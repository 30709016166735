// src/components/Menu/Menu.js
import React, { useState, useEffect } from 'react';
import { Menu as AntMenu, Button, Drawer, Collapse } from 'antd';
import { SearchOutlined, MenuOutlined } from '@ant-design/icons';
import './Menu.css'; // Ensure the updated CSS file is included

const { SubMenu } = AntMenu;
const { Panel } = Collapse;

const AppMenu = () => {
    const [visible, setVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {isMobile ? (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="logo" style={{ flex: 1, textAlign: 'center' }}></div>
                    <Button
                        className="menu-button"
                        type="text"
                        icon={<MenuOutlined />}
                        onClick={showDrawer}
                    />
                </div>
            ) : (
                <AntMenu theme="dark" mode="horizontal" style={{ display: 'flex', justifyContent: 'center', lineHeight: '64px' }}>
                    <SubMenu key="Services" title="Services">
                        <AntMenu.Item key="vulnerability-assessments"><a href="/vulnerability-management">Vulnerability Assessments</a></AntMenu.Item>
                        <AntMenu.Item key="penetration-testing"><a href="/PenetrationTesting">Penetration Testing</a></AntMenu.Item>
                        <AntMenu.Item key="threat-modelling"><a href="/ThreatDetectionandResponse">Threat Modelling</a></AntMenu.Item>
                        <AntMenu.Item key="risk-analysis"><a href="/RiskAnalysis">Risk Analysis</a></AntMenu.Item>
                        <AntMenu.Item key="incident-response"><a href="/IncidentResponseAndForensics">Incident Response</a></AntMenu.Item>
                    </SubMenu>
                    <SubMenu key="Solutions" title="Solutions">
                        <AntMenu.Item key="NetworkSecurityPage"><a href="/NetworkSecurityPage">Network Security</a></AntMenu.Item>
                        <AntMenu.Item key="CloudSecurityPage"><a href="/CloudSecurityPage">Cloud Security</a></AntMenu.Item>
                        <AntMenu.Item key="EndpointProtectionPage"><a href="/EndpointProtectionPage">Endpoint Protection</a></AntMenu.Item>
                        <AntMenu.Item key="ComplianceServicesPage"><a href="/ComplianceServicesPage">Compliance Services</a></AntMenu.Item>
                        <AntMenu.Item key="DataAIPage"><a href="/DataAIPage">Data & AI</a></AntMenu.Item>
                    </SubMenu>
                    <AntMenu.Item key="industries"><a href="/Industries">Industries</a></AntMenu.Item>
                    <AntMenu.Item key="CareersPage"><a href="/CareersPage">Careers</a></AntMenu.Item>
                    <AntMenu.Item key="contact"><a href="/contact">Contact Us</a></AntMenu.Item>
                    <AntMenu.Item key="quote" className="highlighted-menu-item"><a href="/quote">Request a Quote</a></AntMenu.Item>
                </AntMenu>
            )}
            <Drawer
    title="Menu"
    placement="right"
    onClose={onClose}
    visible={visible}
    className="drawer-menu"
>
    <AntMenu mode="vertical">
        <AntMenu.Item key="1">
            <a href="/">Home</a>
        </AntMenu.Item>
        <Collapse accordion>
            <Panel header="Services" key="2">
                <AntMenu.Item key="vulnerability-assessments"><a href="/vulnerability-assessments">Vulnerability Assessments</a></AntMenu.Item>
                <AntMenu.Item key="penetration-testing"><a href="/penetration-testing">Penetration Testing</a></AntMenu.Item>
                <AntMenu.Item key="threat-modelling"><a href="/threat-modelling">Threat Modelling</a></AntMenu.Item>
                <AntMenu.Item key="risk-analysis"><a href="/risk-analysis">Risk Analysis</a></AntMenu.Item>
                <AntMenu.Item key="incident-response"><a href="/incident-response">Incident Response</a></AntMenu.Item>
            </Panel>
            <Panel header="Solutions" key="3">
                <AntMenu.Item key="NetworkSecurityPage"><a href="/NetworkSecurityPage">Network Security</a></AntMenu.Item>
                <AntMenu.Item key="CloudSecurityPage"><a href="/CloudSecurityPage">Cloud Security</a></AntMenu.Item>
                <AntMenu.Item key="EndpointProtectionPage"><a href="/EndpointProtectionPage">Endpoint Protection</a></AntMenu.Item>
                <AntMenu.Item key="ComplianceServicesPage"><a href="/ComplianceServicesPage">Compliance Services</a></AntMenu.Item>
                <AntMenu.Item key="DataAIPage"><a href="/DataAIPage">Data & AI</a></AntMenu.Item>
            </Panel>
        </Collapse>
        <AntMenu.Item key="4">
            <a href="/industries">Industries</a>
        </AntMenu.Item>
        <AntMenu.Item key="5">
            <a href="/CareersPage">Careers</a>
        </AntMenu.Item>
        <AntMenu.Item key="6">
            <a href="/contact">Contact Us</a>
        </AntMenu.Item>
        <AntMenu.Item key="7" className="highlighted-menu-item">
            <a href="/quote">Request a Quote</a>
        </AntMenu.Item>
    </AntMenu>
</Drawer>
        </>
    );
};

export default AppMenu;

import React from 'react';
import { Link } from 'react-router-dom';    
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Button } from 'antd';
const BannerComponent = ({ images }) => {
    return (
        <Carousel
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            infiniteLoop
            autoPlay
            interval={3000}
            transitionTime={500}
            emulateTouch
        >
            {images.map((image, index) => (
               <div
               key={index}
               className="banner-item"
               style={{ backgroundImage: `url(${image.url})` }}
           >
               <div className="banner-content">
                   <h1 style={{ paddingTop: '8%', color: 'white' }}>{image.title}</h1>
                   <h3 style={{ color: 'white' }}>{image.subtitle}</h3>
                   <Link to="/contact">
                       <Button type="dashed" style={{ marginTop: '10px', marginBottom: '10px' }}>Contact Us</Button>
                   </Link>
               </div>
           </div>
            ))}
        </Carousel>
    );
};

export default BannerComponent;

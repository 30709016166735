import React, { useState, useEffect } from 'react';
import { Layout, Menu, Table, Spin } from 'antd';
import axios from 'axios';
import './AdminDashboard.css'; // Add your custom styles if needed

const { Sider, Content } = Layout;

const AdminDashboard = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://vaptix.com/php/quotes.php'); // Update with your API endpoint for quotes
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Optionally, set an error state to display an error message
        }
        setLoading(false);
    };

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Phone', dataIndex: 'phone', key: 'phone' },
        { title: 'Company', dataIndex: 'company', key: 'company' },
        { title: 'Services', dataIndex: 'services', key: 'services' },
        { title: 'Created At', dataIndex: 'created_at', key: 'created_at' }
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider width={250} className="site-layout-background">
                <Menu mode="inline" selectedKeys={['quotes']} disabled>
                    <Menu.Item key="quotes">Quotes</Menu.Item>
                </Menu>
            </Sider>
            <Layout style={{ padding: '0 24px', minHeight: 'calc(100vh - 64px)' }}>
                <Content
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    {loading ? (
                        <Spin tip="Loading..." />
                    ) : (
                        <Table
                            dataSource={data}
                            columns={columns}
                            rowKey="id"
                            pagination={{ pageSize: 10 }} // Add pagination if needed
                        />
                    )}
                </Content>
            </Layout>
        </Layout>
    );
};

export default AdminDashboard;

import React from 'react';
import { Row, Col, Card, Typography, Button } from 'antd';
import './ServiceStyle.css'; // Import styles for service pages
import { RightCircleTwoTone } from '@ant-design/icons';
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const AIEnabledCyberSecurity = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">AI Enabled Cyber Security: Advanced Protection Through Innovation</Title>
                <Paragraph className="hero-subtitle">Harnessing AI for Unmatched Cyber Defense</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="even-row">
                    <Col span={24}>
                        <Paragraph>
                            In an era where cyber threats are constantly evolving, AI enabled cyber security provides a powerful defense. At [Your Company Name], we leverage advanced artificial intelligence and machine learning technologies to deliver state-of-the-art cyber security solutions. Our expert team ensures that your digital assets are protected with the latest innovations in cyber defense.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]}>
                    <Col span={24}>
                        <Title level={2}>Why Choose Our AI Enabled Cyber Security Services?</Title>
                        <ul className="feature-list">
                            <li><RightCircleTwoTone /> <strong>Proactive Threat Detection:</strong> Our AI systems continuously monitor and analyze data to detect threats before they cause harm.</li>
                            <li><RightCircleTwoTone /> <strong>Adaptive Defense:</strong> AI driven solutions adapt to new threats, providing a dynamic defense that evolves with the cyber landscape.</li>
                            <li><RightCircleTwoTone /> <strong>Reduced False Positives:</strong> Advanced machine learning algorithms enhance detection accuracy, minimizing false positives and ensuring real threats are addressed.</li>
                            <li><RightCircleTwoTone /> <strong>Comprehensive Coverage:</strong> Our AI enabled solutions provide comprehensive protection across all digital assets, ensuring no vulnerability is left unaddressed.</li>
                            <li><RightCircleTwoTone /> <strong>Expert Support:</strong> Our team of cyber security experts is always available to provide support and ensure the effectiveness of our AI solutions.</li>
                        </ul>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="even-row">
                    <Col span={24}>
                        <Title level={2}>Key Features of Our AI Enabled Cyber Security Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Real-Time Threat Analysis</Title>
                                    <Paragraph>Our AI systems analyze data in real-time to detect and respond to threats instantly, ensuring your systems remain secure.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Behavioral Analysis</Title>
                                    <Paragraph>Leveraging machine learning, we analyze user and entity behavior to identify unusual activities that may indicate a security threat.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Automated Response</Title>
                                    <Paragraph>Our AI driven solutions provide automated responses to threats, reducing response times and mitigating risks efficiently.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Predictive Analytics</Title>
                                    <Paragraph>We use predictive analytics to anticipate potential threats and vulnerabilities, allowing for proactive security measures.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]}>
                    <Col span={24}>
                        <Title level={2}>Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Security</Title>
                                    <Paragraph>Benefit from advanced AI technologies that provide superior protection for your digital assets.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Cost Efficiency</Title>
                                    <Paragraph>Reduce the cost of cyber security management with automated AI solutions that provide effective and efficient protection.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Peace of Mind</Title>
                                    <Paragraph>Rest assured knowing that your systems are protected by the latest advancements in AI enabled cyber security.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Support</Title>
                                    <Paragraph>Our dedicated support team is available 24/7 to assist with any cyber security issues, ensuring your peace of mind.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AIEnabledCyberSecurity;

// src/components/Footer/Footer.js
import React from 'react';
import { Row, Col, Typography, Divider } from 'antd';
import { FacebookOutlined, TwitterOutlined, InstagramOutlined } from '@ant-design/icons';
import './Footer.css'; // Ensure to create and include this CSS file

const { Title, Text } = Typography;

const Footer = () => {
    return (
        <footer className="footer">
            <Divider />
            <Row className="footer-content">
                <Col span={24} md={8}>
                    <Title level={4}>About Us</Title>
                    <Text>Vaptix specializes in Cyber Security.</Text>
                </Col>
                <Col span={24} md={8}>
                    <Title level={4}>Quick Links</Title>
                    <ul className="footer-links">
                        <li><a href="/">Home</a></li>
                        <li><a href="/vulnerability-management">Vulnerability Management</a></li>
                        <li><a href="/PenetrationTesting">Penetration Testing</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                        <li><a href="/quote">Request a Quote</a></li>
                    </ul>
                </Col>
                <Col span={24} md={8}>
                    <Title level={4}>Follow Us</Title>
                    <div className="social-icons">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FacebookOutlined /></a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><TwitterOutlined /></a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><InstagramOutlined /></a>
                    </div>
                </Col>
            </Row>
            <Divider />
            <div className="footer-bottom">
                <Text className="footer-bottom">© 2024 CalmConquer Technologies. All rights reserved.</Text>
            </div>
        </footer>
    );
};

export default Footer;

// src/components/Header/Header.js
import React from 'react';
import { Layout } from 'antd';
import Logo from './Logo';
import Menu from '../Menu/Menu';

const { Header } = Layout;

const AppHeader = () => (
  <Header>
    <Logo />
    <Menu />
  </Header>
);

export default AppHeader;

import React from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css';
import './pagestyle.css';
import BannerComponent from '../components/BannerComponent';
import incidentResponseImage from '../assect/images/vm3.jpeg'; // Update path to your image

const { Title, Paragraph } = Typography;

const bannerImages = [
    { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
];

const pageHeaderImages = [
    { url: incidentResponseImage, title: 'Effective Incident Response', subtitle: 'Respond Quickly and Effectively to Security Incidents' }
];

const IncidentResponsePage = () => {
    return (
        <div className="service-page">
            <BannerComponent images={pageHeaderImages} />

            <div className="content-section">
                <Row gutter={[16, 16]} className="even-row" style={{ display: 'flex', alignItems: 'center' }}>
                    <Col xs={24} sm={24} md={16} lg={16}>
                        <Paragraph className="main-paragraph">
                            Effective incident response is crucial for minimizing the impact of security incidents on your organization. Our Incident Response services are designed to provide rapid and effective responses to security threats. We offer comprehensive incident management, including detection, containment, eradication, and recovery, to ensure that your organization can quickly return to normal operations with minimal disruption.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div style={{ float: 'right' }}>
                            <img src={incidentResponseImage} alt="Incident Response" className="responsive-img" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section section-list">
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Title level={2} className="section-title">Why Choose Our Incident Response Services?</Title>
                        <Divider className="section-divider" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card>
                            <ul className="feature-list">
                                <li><RightCircleTwoTone /> <strong>Rapid Response:</strong> Quickly address and contain security incidents to minimize impact.</li>
                                <li><RightCircleTwoTone /> <strong>Comprehensive Management:</strong> Manage incidents from detection to recovery with our end-to-end services.</li>
                                <li><RightCircleTwoTone /> <strong>Expert Team:</strong> Leverage our team of experts to handle complex security incidents.</li>
                                <li><RightCircleTwoTone /> <strong>Minimized Disruption:</strong> Ensure minimal disruption to your organization's operations during and after an incident.</li>
                                <li><RightCircleTwoTone /> <strong>Post-Incident Analysis:</strong> Analyze incidents to improve future response strategies and enhance security posture.</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Key Features of Our Incident Response Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Rapid Incident Containment</Title>
                                    <Paragraph className="card-paragraph">Quickly contain incidents to prevent further damage.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Comprehensive Incident Management</Title>
                                    <Paragraph className="card-paragraph">Manage all aspects of incident response from detection to recovery.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Expert Team Support</Title>
                                    <Paragraph className="card-paragraph">Access a team of experts to handle and resolve complex incidents.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Post-Incident Analysis</Title>
                                    <Paragraph className="card-paragraph">Analyze incidents to enhance future response strategies.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <BannerComponent images={bannerImages} />

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Swift Response</Title>
                                    <Paragraph className="card-paragraph">Minimize the impact of incidents with our swift response services.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Comprehensive Support</Title>
                                    <Paragraph className="card-paragraph">Receive end-to-end support from detection through recovery.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Analysis</Title>
                                    <Paragraph className="card-paragraph">Benefit from expert analysis to improve future incident responses.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default IncidentResponsePage;

import React from 'react';
import { Row, Col, Card, Typography, Button } from 'antd';
import './ServiceStyle.css'; // Import styles for service pages
import { RightCircleTwoTone } from '@ant-design/icons';
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const WhyChooseVAPTIX = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Why Choose VAPTIX?</Title>
                <Paragraph className="hero-subtitle">Excellence in Cyber Security Services</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="even-row">
                    <Col span={24}>
                        <Paragraph>
                            At VAPTIX, we are committed to providing top-notch cyber security services to protect your digital assets. Here’s why you should choose us as your trusted partner in cyber security.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]}>
                    <Col span={24}>
                        <Title level={2}>Our Core Strengths</Title>
                        <ul className="feature-list">
                            <li><RightCircleTwoTone /> <strong>Expertise and Experience:</strong> Our team comprises seasoned cyber security professionals with extensive experience in various industries, ensuring the highest level of expertise.</li>
                            <li><RightCircleTwoTone /> <strong>Proactive Approach:</strong> We adopt a proactive approach to cyber security, anticipating potential threats and mitigating them before they can cause harm.</li>
                            <li><RightCircleTwoTone /> <strong>Customized Solutions:</strong> We understand that every business is unique, and we tailor our cyber security solutions to meet your specific needs and requirements.</li>
                            <li><RightCircleTwoTone /> <strong>Commitment to Excellence:</strong> Our commitment to excellence drives us to deliver superior cyber security services, ensuring your digital assets are always protected.</li>
                        </ul>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="even-row">
                    <Col span={24}>
                        <Title level={2}>Key Features of Our Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Comprehensive Assessments</Title>
                                    <Paragraph>We conduct thorough security assessments to identify vulnerabilities and provide detailed reports with actionable recommendations.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Real-Time Monitoring</Title>
                                    <Paragraph>Our advanced monitoring systems provide real-time alerts and analysis to keep your digital assets secure around the clock.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Expert Remediation</Title>
                                    <Paragraph>Our team offers expert remediation services to address identified vulnerabilities and strengthen your security posture.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Continuous Improvement</Title>
                                    <Paragraph>We believe in continuous improvement and regularly update our methodologies and technologies to stay ahead of emerging threats.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]}>
                    <Col span={24}>
                        <Title level={2}>Benefits of Partnering with VAPTIX</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Security</Title>
                                    <Paragraph>Experience enhanced security for your digital assets with our comprehensive and tailored cyber security solutions.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Peace of Mind</Title>
                                    <Paragraph>Rest assured knowing that your cyber security is in the hands of experienced professionals committed to protecting your business.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Cost Efficiency</Title>
                                    <Paragraph>Our proactive and customized approach helps reduce the overall cost of managing and mitigating cyber threats.</Paragraph>
                                </Card>
                            </Col>
                            <Col  xs={24} sm={12} md={12} lg={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>24/7 Support</Title>
                                    <Paragraph>Our dedicated support team is available 24/7 to assist with any cyber security issues, ensuring continuous protection and peace of mind.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default WhyChooseVAPTIX;

import React, { useState } from 'react';
import { Row, Col, Card, Typography, Form, Input, Button, Steps, Modal } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const SendQuote = () => {
    const [modalVisible, setModalVisible] = useState(false); // State for controlling modal visibility
    const [form] = Form.useForm(); // Create form instance

    const handleSubmit = async (values) => {
        console.log('Received values of form:', values);
    
        // Post form data to PHP script
        try {
            const response = await fetch('https://vaptix.com/php/handleQuote.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(values).toString(),
            });
    
            const result = await response.text();
            console.log(result);
    
            // Show modal on successful form submission
            setModalVisible(true);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleModalOk = () => {
        setModalVisible(false);
        form.resetFields(); // Clear form fields
    };

    const description1 = 'Our team will review your request and gather any additional information if needed.';
    const description2 = 'We will prepare a detailed proposal outlining the scope of work, timelines, and pricing.';
    const description3 = 'You will receive the proposal via email for your review and consideration.';

    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Request a Quote</Title>
                <Paragraph className="hero-subtitle">Get a tailored quote for our services</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="even-row">
                    <Col span={24}>
                        <Paragraph>
                            At Vaptix, we are committed to providing services that meet your specific needs. Fill out the form below to request a customized quote for our services. Our team will review your requirements and get back to you with a detailed proposal as soon as possible.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]}>
                    <Col xs={24} sm={24} md={12} lg={14}>
                        <Card bordered={true} className="even-row">
                            <Form layout="vertical" form={form} onFinish={handleSubmit}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter your name' }]}>
                                            <Input placeholder="Your Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
                                            <Input placeholder="Your Email" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item label="Phone" name="phone">
                                            <Input placeholder="Your Phone Number" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item label="Company Name" name="company">
                                            <Input placeholder="Your Company Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <Form.Item label="Services Required" name="services" rules={[{ required: true, message: 'Please specify the services you need' }]}>
                                            <TextArea rows={4} placeholder="Describe the services you need" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <Button type="primary" htmlType="submit" icon={<RightCircleTwoTone />}>
                                            Request Quote
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Title level={2}>What Happens Next?</Title>
                        <Steps
                            direction="vertical"
                            current={1}
                            items={[
                                {
                                    title: 'Quote Review',
                                    description: description1,
                                },
                                {
                                    title: 'Proposal Preparation',
                                    description: description2,
                                },
                                {
                                    title: 'Proposal Sent',
                                    description: description3,
                                },
                                {
                                    title: 'Follow-up',
                                    description: description3,
                                },
                            ]}
                        />
                    </Col>
                </Row>
                
                <Modal
                    title="Congratulations!"
                    visible={modalVisible}
                    onOk={handleModalOk}
                    centered // Center modal on screen
                    className="custom-modal" // Add custom class for styling
                >
                    <div className="modal-content">
                        <p>We have received your quote request.</p>
                        <p>Our team will contact you shortly to discuss further details.</p>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default SendQuote;

import React from 'react';
import { Row, Col, Card, Typography, Form, Input, Button } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const ContactUs = () => {
    const [form] = Form.useForm(); // Create form instance

    const handleSubmit = async (values) => {
        console.log('Submitted values:', values);

        // Post form data to PHP script
        try {
            const response = await fetch('https://vaptix.com/php/handleContact.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(values).toString(),
            });

            const result = await response.text();
            console.log(result);
            // You might want to show a success message or clear the form here
            form.resetFields(); // Clear form fields
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Contact Us</Title>
                <Paragraph className="hero-subtitle">We'd love to hear from you! Get in touch with us.</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            At Vaptix, we value your feedback and inquiries. Whether you have a question about our services, need support, or just want to learn more about what we do, our team is here to assist you. Please fill out the form below, and we will get back to you as soon as possible.
                        </Paragraph>
                    </Col>
                </Row>
               
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card bordered={false}>
                            <Form layout="vertical" form={form} onFinish={handleSubmit}>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter your name' }]}>
                                            <Input placeholder="Your Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
                                            <Input placeholder="Your Email" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Phone" name="phone">
                                            <Input placeholder="Your Phone Number" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Subject" name="subject">
                                            <Input placeholder="Subject" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Message" name="message" rules={[{ required: true, message: 'Please enter your message' }]}>
                                            <TextArea rows={4} placeholder="Your Message" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Button type="primary" htmlType="submit" icon={<RightCircleTwoTone />}>
                                            Send Message
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Title style={{ textAlign: 'center' }} level={2}>Our Contact Details</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Office Address</Title>
                                    <Paragraph>8-30/1/5/6, Street No. 4B</Paragraph>
                                    <Paragraph>Hema Nagar , Boduppal, Hydrabad</Paragraph>
                                    <Paragraph>Telengana 500092</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Phone</Title>
                                    <Paragraph>9959113097</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ContactUs;

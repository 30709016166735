import React from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css';
import './pagestyle.css';
import BannerComponent from '../components/BannerComponent';
import vulnerabilityManagementImage from '../assect/images/vm4.jpeg'; // Update path to your image

const { Title, Paragraph } = Typography;

const bannerImages = [
    { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
];

const pageHeaderImages = [
    { url: vulnerabilityManagementImage, title: 'Comprehensive Vulnerability Management', subtitle: 'Identify and Address Security Weaknesses' }
];

const VulnerabilityManagementPage = () => {
    return (
        <div className="service-page">
            <BannerComponent images={pageHeaderImages} />

            <div className="content-section">
                <Row gutter={[16, 16]} className="even-row" style={{ display: 'flex', alignItems: 'center' }}>
                    <Col xs={24} sm={24} md={16} lg={16}>
                        <Paragraph className="main-paragraph">
                            Vulnerability management is crucial for identifying and addressing security weaknesses before they can be exploited. At Vaptix, we offer a robust vulnerability management service that includes regular scans, detailed assessments, and actionable remediation strategies to enhance your security posture. Our approach ensures that potential vulnerabilities are promptly identified and mitigated to safeguard your systems.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div style={{ float: 'right' }}>
                            <img src={vulnerabilityManagementImage} alt="Vulnerability Management" className="responsive-img" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section section-list">
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Title level={2} className="section-title">Why Choose Our Vulnerability Management Services?</Title>
                        <Divider className="section-divider" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card>
                            <ul className="feature-list">
                                <li><RightCircleTwoTone /> <strong>Regular Scans:</strong> Conduct frequent scans to detect vulnerabilities across your systems.</li>
                                <li><RightCircleTwoTone /> <strong>Detailed Assessments:</strong> Perform thorough assessments to understand the impact and risk of identified vulnerabilities.</li>
                                <li><RightCircleTwoTone /> <strong>Actionable Remediation:</strong> Provide clear guidance and strategies for addressing and mitigating vulnerabilities.</li>
                                <li><RightCircleTwoTone /> <strong>Compliance Support:</strong> Ensure compliance with industry standards and regulations through effective vulnerability management.</li>
                                <li><RightCircleTwoTone /> <strong>Continuous Monitoring:</strong> Implement ongoing monitoring to detect new vulnerabilities and respond promptly.</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Key Features of Our Vulnerability Management Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Automated Scanning</Title>
                                    <Paragraph className="card-paragraph">Leverage automated tools to regularly scan for vulnerabilities across your network.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Risk Prioritization</Title>
                                    <Paragraph className="card-paragraph">Prioritize vulnerabilities based on risk and potential impact to focus on critical issues.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Comprehensive Reporting</Title>
                                    <Paragraph className="card-paragraph">Generate detailed reports to track vulnerabilities, remediation efforts, and progress.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Expert Guidance</Title>
                                    <Paragraph className="card-paragraph">Receive expert advice on best practices for managing and mitigating vulnerabilities.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <BannerComponent images={bannerImages} />

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Security</Title>
                                    <Paragraph className="card-paragraph">Improve your security posture by identifying and addressing vulnerabilities effectively.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Proactive Risk Management</Title>
                                    <Paragraph className="card-paragraph">Adopt a proactive approach to risk management by regularly assessing and mitigating vulnerabilities.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Compliance Assurance</Title>
                                    <Paragraph className="card-paragraph">Ensure compliance with regulatory requirements through effective vulnerability management.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default VulnerabilityManagementPage;

import React from 'react';
import { Row, Col, Card, Typography, Button } from 'antd';
import './ServiceStyle.css'; // Import styles for service pages
import { RightCircleTwoTone } from '@ant-design/icons';
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const ComplianceManagement = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Comprehensive Compliance Management: Your Path to Regulatory Assurance</Title>
                <Paragraph className="hero-subtitle">Ensuring Adherence to Standards and Regulations</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="even-row">
                    <Col span={24}>
                        <Paragraph>
                            Navigating the complex landscape of regulatory compliance can be challenging. At [Your Company Name], we offer comprehensive compliance management services to help your organization adhere to industry standards and regulations. Our expert team and advanced technology ensure that your business remains compliant, reducing risk and enhancing operational efficiency.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]}>
                    <Col span={24}>
                        <Title level={2}>Why Choose Our Compliance Management Services?</Title>
                        <ul className="feature-list">
                            <li><RightCircleTwoTone /> <strong>Expert Guidance:</strong> Our compliance experts provide detailed guidance on regulatory requirements, ensuring your organization stays compliant.</li>
                            <li><RightCircleTwoTone /> <strong>Comprehensive Audits:</strong> We conduct thorough compliance audits to identify gaps and implement corrective actions.</li>
                            <li><RightCircleTwoTone /> <strong>Advanced Reporting:</strong> Our reporting tools provide detailed insights into your compliance status, helping you make informed decisions.</li>
                            <li><RightCircleTwoTone /> <strong>Custom Solutions:</strong> We tailor our compliance management services to meet the unique needs of your business, ensuring effective compliance.</li>
                            <li><RightCircleTwoTone /> <strong>Continuous Monitoring:</strong> Our continuous monitoring services ensure that you remain compliant with evolving regulations.</li>
                        </ul>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="even-row">
                    <Col span={24}>
                        <Title level={2}>Key Features of Our Compliance Management Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Regulatory Expertise</Title>
                                    <Paragraph>Our team of compliance experts has extensive knowledge of various regulatory frameworks, ensuring your organization meets all necessary requirements.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Detailed Audits</Title>
                                    <Paragraph>We conduct comprehensive audits to identify compliance gaps and implement corrective actions, ensuring your organization remains compliant.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Advanced Reporting</Title>
                                    <Paragraph>Our reporting tools provide detailed insights into your compliance status, helping you make informed decisions and maintain regulatory adherence.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Continuous Monitoring</Title>
                                    <Paragraph>We offer continuous monitoring services to ensure your organization stays compliant with evolving regulations and standards.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]}>
                    <Col span={24}>
                        <Title level={2}>Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Reduced Risk</Title>
                                    <Paragraph>Minimize the risk of non-compliance with our expert guidance and comprehensive audits, ensuring your organization adheres to regulatory requirements.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Operational Efficiency</Title>
                                    <Paragraph>Improve your operational efficiency with our tailored compliance solutions, allowing you to focus on your core business activities.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Peace of Mind</Title>
                                    <Paragraph>Rest easy knowing that your organization is compliant with industry standards and regulations, reducing the risk of penalties and reputational damage.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Support</Title>
                                    <Paragraph>Our dedicated support team is available 24/7 to assist you with any compliance-related queries or issues, ensuring a seamless compliance management experience.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ComplianceManagement;

import React from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles
import BannerComponent from '../components/BannerComponent';
import cloudSecurityImage from '../assect/images/elearning.jpeg'; // Update path to your cloud security image

const { Title, Paragraph } = Typography;

const bannerImages = [
    { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
];

const pageHeaderImages = [
    { url: cloudSecurityImage, title: 'Comprehensive Cloud Security: Protect Your Data in the Cloud', subtitle: 'Advanced Solutions for Your Cloud Infrastructure' }
];

const CloudSecurityPage = () => {
    return (
        <div className="service-page">
            <BannerComponent images={pageHeaderImages} />

            <div className="content-section">
                <Row gutter={[16, 16]} className="even-row" style={ {display: 'flex',
        alignItems: 'center',}}>
                    <Col xs={24} sm={24} md={16} lg={16}>
                        <Paragraph className="main-paragraph">
                            In the era of cloud computing, securing your cloud infrastructure is essential to protect your data and applications. At Vaptix, we offer cutting-edge cloud security solutions to safeguard your cloud environment. Our services include cloud threat detection, encryption, identity and access management, and continuous monitoring to ensure your cloud resources are secure from potential threats.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div style={{float: 'right'}}><img src={cloudSecurityImage} alt="Security Monitoring" className="responsive-img" style={{ width: '100%', height: 'auto' }} /></div>
                        
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section section-list" >
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Title level={2} className="section-title">Why Choose Our Cloud Security Solutions?</Title>
                        <Divider className="section-divider" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card>
                            <ul className="feature-list">
                                <li><RightCircleTwoTone /> <strong>Cloud Threat Detection:</strong> Utilize advanced technology to detect and respond to threats targeting your cloud infrastructure in real-time.</li>
                                <li><RightCircleTwoTone /> <strong>Data Encryption:</strong> Protect your data with robust encryption methods to prevent unauthorized access and data breaches.</li>
                                <li><RightCircleTwoTone /> <strong>Identity and Access Management:</strong> Manage user access and permissions to ensure that only authorized individuals have access to your cloud resources.</li>
                                <li><RightCircleTwoTone /> <strong>Continuous Monitoring:</strong> Implement ongoing surveillance to detect and address potential security incidents promptly.</li>
                                <li><RightCircleTwoTone /> <strong>Compliance and Reporting:</strong> Ensure your cloud security practices meet industry standards and regulatory requirements with comprehensive reporting.</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title" >Key Features of Our Cloud Security Solutions</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Advanced Threat Detection</Title>
                                    <Paragraph className="card-paragraph">Receive real-time alerts about potential threats targeting your cloud infrastructure to stay ahead of attacks.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Comprehensive Data Encryption</Title>
                                    <Paragraph className="card-paragraph">Encrypt your data both in transit and at rest to safeguard it from unauthorized access and breaches.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Robust Identity Management</Title>
                                    <Paragraph className="card-paragraph">Manage user identities and access permissions effectively to control who can access your cloud resources.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">24/7 Cloud Surveillance</Title>
                                    <Paragraph className="card-paragraph">Our team monitors your cloud environment around the clock to identify and mitigate potential security issues.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <BannerComponent images={bannerImages} />

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title" >Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Cloud Security</Title>
                                    <Paragraph className="card-paragraph">Protect your cloud infrastructure with our advanced security measures and best practices.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Cost Efficiency</Title>
                                    <Paragraph className="card-paragraph">Reduce the risk of data breaches and associated costs with our proactive cloud security solutions.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Guidance</Title>
                                    <Paragraph className="card-paragraph">Leverage the expertise of our cloud security professionals for continuous support and incident management.</Paragraph>
                                </Card>
                            </Col>
                            
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default CloudSecurityPage;

import React from 'react';
import { Row, Col } from 'antd';
import './pagestyle.css'; // Import homepage styles
import CarouselComponent from '../components/CarouselComponent';
import Card from '../components/Card';
import IndustrySection from '../components/IndustrySection';
import BannerComponent from '../components/BannerComponent';
// Import images from the 'src/assets/images' directory
import vaptixBanner1 from '../assect/images/vm1.jpeg';
import vaptixBanner2 from '../assect/images/vaptix_banner3.jpg';
import medicare from '../assect/images/medicare.jpeg';
import cybersic from '../assect/images/cybersic.jpeg';
import it from '../assect/images/it.jpeg';
import Banking from '../assect/images/banking.jpeg';
import IoT from '../assect/images/iot.jpeg';
import spartgrid from '../assect/images/smartgrid.jpg';
import ecommerce from '../assect/images/ecommerce.jpeg';
import elearning from '../assect/images/elearning.jpeg';

// services

import everytimemonitoring from '../assect/images/247.jpeg';
import Vulnerability from '../assect/images/vm4.jpeg';
import Threat from '../assect/images/vm2.jpeg';
import TW from '../assect/images/teamwork2.jpeg';
import allwork from '../assect/images/allwork.jpeg';
const Homepage = () => {
    const serviceImages = [
        { url: vaptixBanner1, title: 'Welcome to VAPTIX', subtitle: 'Your Trusted Managed Security Services Provider' },
        { url: vaptixBanner2, title: 'Secure Your Digital Assets', subtitle: 'Comprehensive solutions to protect your organization\'s data' }
    ];

    const industries = [
        { title: 'Healthcare', img: medicare },
        { title: 'Cybersecurity solutions', img: cybersic },
        { title: 'Technology and IT', img: it },
        { title: 'Finance and Banking', img: Banking },
        { title: 'E-commerce security solutions', img: ecommerce },
        { title: 'Industrial IoT solutions', img: IoT },
        { title: 'Secure e-learning platforms', img: elearning },
        { title: 'Smart grid technologies', img: spartgrid },
        { title: 'Public service management systems', img: it }
    ];

    const bannerImages = [
        { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
    ];

    return (
        <div className="homepage">
            <CarouselComponent images={serviceImages} />

            <Row className="even-row" gutter={[16, 16]} style={{paddingTop:'60px'}}>
                <Col span={24}>
                    <h1 style={{fontSize:'40px',fontWeight:'bold'}}>Services</h1>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card
                        title="24/7 Security Monitoring"
                        description="Our dedicated Security Operations Center (SOC) provides round-the-clock monitoring and analysis of your IT environment, detecting and responding to security incidents in real-time."
                        img={everytimemonitoring}
                        link="/security-monitoring"
                    />
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card
                        title="Vulnerability Management"
                        description="With our proactive vulnerability management services, we identify and remediate security weaknesses before they can be exploited by attackers, helping to fortify your defenses against potential breaches."
                        img={Vulnerability}
                        link="/vulnerability-management"
                    />
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card
                        title="Threat Detection and Response"
                        description="Our advanced threat detection capabilities leverage cutting-edge technology to identify and mitigate cyber threats, including malware, ransomware, and insider threats, minimizing the impact on your business operations."
                        img={Threat}
                        link="/ThreatDetectionandResponse"
                    />
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card 
                        title="Incident Response and Forensics"
                        description="In the event of a security incident, our expert incident response team is on standby to swiftly contain the threat, mitigate damages, and conduct thorough forensic analysis to prevent future occurrences."
                        img={elearning}
                        link="/IncidentResponseAndForensics"
                        style={{ width: '100%', maxWidth: '50%' }}
                    />
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card
                        title="AI Enabled Cyber Security"
                        description="Our AI-enabled cybersecurity solutions leverage machine learning and artificial intelligence algorithms to detect and prevent cyber threats in real-time, providing enhanced protection for your digital assets."
                        img={allwork}
                        link="/AIEnabledCyberSecurity"
                    />
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card
                        title="Compliance Management"
                        description="We help ensure that your organization remains compliant with industry regulations and standards such as HIPAA, PCI DSS, GDPR, and more, providing comprehensive compliance management solutions tailored to your specific requirements."
                        img={TW}
                        link="/ComplianceManagement"
                    />
                </Col>
            </Row>

            <Row className="even-row" gutter={[16, 16]} style={{paddingTop:'60px'}}>
                <Col span={24}>
                    <h1 style={{fontSize:'40px',fontWeight:'bold'}}>Why Choose VAPTIX?</h1>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card
                        title="Expertise and Experience"
                        description="Our team of seasoned cybersecurity professionals brings years of experience and expertise in the field, delivering customized solutions to address your unique security challenges."
                        img="https://vaptix.com/assets/images/vm.jpg"
                        link="/WhyChooseVAPTIX"
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card
                        title="Proactive Approach"
                        description="We take a proactive approach to cybersecurity, leveraging advanced technologies and threat intelligence to stay ahead of emerging threats and mitigate risks before they materialize into breaches."
                        img="https://vaptix.com/assets/images/vm.jpg"
                        link="/WhyChooseVAPTIX"
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card
                        title="Customized Solutions"
                        description="We understand that every organization has unique security needs, which is why we offer tailored solutions that are scalable, flexible, and aligned with your business objectives."
                        img="https://vaptix.com/assets/images/vm.jpg"
                        link="/WhyChooseVAPTIX"
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Card
                        title="Commitment to Excellence"
                        description="At VAPTIX, we are committed to excellence in everything we do, from delivering exceptional service to providing ongoing support and guidance to our clients."
                        img="https://vaptix.com/assets/images/vm.jpg"
                        link="/WhyChooseVAPTIX"
                    />
                </Col>
            </Row>

            <BannerComponent images={bannerImages} />

            <IndustrySection industries={industries} />
        </div>
    );
};

export default Homepage;

import React from 'react';
import { Carousel } from 'antd';
import './CarouselComponent.css';

const CarouselComponent = ({ images }) => {
    return (
        <Carousel autoplay className="carousel-component">
            {images.map((image, index) => (
                <div key={index} className="carousel-slide">
                    <img src={image.url} alt={image.title} />
                    <div className="carousel-content">
                        <h1>{image.title}</h1>
                        <p>{image.subtitle}</p>
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default CarouselComponent;

import React from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css';
import './pagestyle.css';
import BannerComponent from '../components/BannerComponent';
import penetrationTestingImage from '../assect/images/security.jpeg'; // Update path to your image

const { Title, Paragraph } = Typography;

const bannerImages = [
    { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
];

const pageHeaderImages = [
    { url: penetrationTestingImage, title: 'Effective Penetration Testing Services', subtitle: 'Identify and Address Security Vulnerabilities' }
];

const PenetrationTestingPage = () => {
    return (
        <div className="service-page">
            <BannerComponent images={pageHeaderImages} />

            <div className="content-section">
                <Row gutter={[16, 16]} className="even-row" style={{ display: 'flex', alignItems: 'center' }}>
                    <Col xs={24} sm={24} md={16} lg={16}>
                        <Paragraph className="main-paragraph">
                            Penetration testing is an essential component of a comprehensive security strategy. Our expert team at Vaptix conducts rigorous penetration tests to simulate real-world attacks, uncover vulnerabilities, and assess your security defenses. We provide detailed reports and actionable recommendations to help you strengthen your security posture and protect your assets from potential threats.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div style={{ float: 'right' }}>
                            <img src={penetrationTestingImage} alt="Penetration Testing" className="responsive-img" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section section-list">
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Title level={2} className="section-title">Why Choose Our Penetration Testing Services?</Title>
                        <Divider className="section-divider" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card>
                            <ul className="feature-list">
                                <li><RightCircleTwoTone /> <strong>Real-World Simulation:</strong> Simulate real-world attacks to uncover vulnerabilities that could be exploited by attackers.</li>
                                <li><RightCircleTwoTone /> <strong>Detailed Reporting:</strong> Receive comprehensive reports with findings, risk assessments, and actionable recommendations.</li>
                                <li><RightCircleTwoTone /> <strong>Expert Analysis:</strong> Benefit from expert analysis and insights to understand and address identified vulnerabilities.</li>
                                <li><RightCircleTwoTone /> <strong>Improved Security Posture:</strong> Strengthen your security posture by addressing vulnerabilities and implementing effective controls.</li>
                                <li><RightCircleTwoTone /> <strong>Compliance Support:</strong> Ensure compliance with industry standards and regulations through thorough testing.</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Key Features of Our Penetration Testing Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Comprehensive Testing</Title>
                                    <Paragraph className="card-paragraph">Conduct thorough testing to identify vulnerabilities across your entire system.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Realistic Attack Simulation</Title>
                                    <Paragraph className="card-paragraph">Simulate real-world attacks to uncover vulnerabilities that could be exploited.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Detailed Findings</Title>
                                    <Paragraph className="card-paragraph">Receive detailed findings and risk assessments with actionable recommendations.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Expert Recommendations</Title>
                                    <Paragraph className="card-paragraph">Get expert recommendations to address vulnerabilities and improve your security posture.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <BannerComponent images={bannerImages} />

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Security</Title>
                                    <Paragraph className="card-paragraph">Identify and address security vulnerabilities to protect your systems from potential threats.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Insights</Title>
                                    <Paragraph className="card-paragraph">Gain expert insights into your security posture and how to improve it.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Comprehensive Testing</Title>
                                    <Paragraph className="card-paragraph">Ensure thorough testing and analysis to uncover all potential vulnerabilities.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default PenetrationTestingPage;

import React from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css';
import './pagestyle.css';
import BannerComponent from '../components/BannerComponent';
import threatDetectionImage from '../assect/images/thread.jpeg'; // Update path to your image

const { Title, Paragraph } = Typography;

const bannerImages = [
    { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
];

const pageHeaderImages = [
    { url: threatDetectionImage, title: 'Advanced Threat Detection and Response', subtitle: 'Proactive Defense Against Cyber Threats' }
];

const ThreatDetectionAndResponsePage = () => {
    return (
        <div className="service-page">
            <BannerComponent images={pageHeaderImages} />

            <div className="content-section">
                <Row gutter={[16, 16]} className="even-row" style={{ display: 'flex', alignItems: 'center' }}>
                    <Col xs={24} sm={24} md={16} lg={16}>
                        <Paragraph className="main-paragraph">
                            In today's digital landscape, timely threat detection and effective response are crucial for maintaining cybersecurity. Our Threat Detection and Response services provide you with advanced tools and expertise to identify, analyze, and respond to potential threats in real time. With a focus on proactive defense and rapid response, we help you protect your organization from emerging cyber threats and minimize potential damage.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div style={{ float: 'right' }}>
                            <img src={threatDetectionImage} alt="Threat Detection" className="responsive-img" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section section-list">
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Title level={2} className="section-title">Why Choose Our Threat Detection and Response Services?</Title>
                        <Divider className="section-divider" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card>
                            <ul className="feature-list">
                                <li><RightCircleTwoTone /> <strong>Real-Time Monitoring:</strong> Continuously monitor your systems for suspicious activities and potential threats.</li>
                                <li><RightCircleTwoTone /> <strong>Advanced Analytics:</strong> Utilize advanced analytics to identify and assess threats accurately.</li>
                                <li><RightCircleTwoTone /> <strong>Rapid Response:</strong> Implement immediate response strategies to mitigate threats and minimize impact.</li>
                                <li><RightCircleTwoTone /> <strong>Incident Management:</strong> Manage and coordinate responses to security incidents effectively.</li>
                                <li><RightCircleTwoTone /> <strong>Comprehensive Reporting:</strong> Receive detailed reports and insights on threat detection and response activities.</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Key Features of Our Threat Detection and Response Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Real-Time Threat Monitoring</Title>
                                    <Paragraph className="card-paragraph">Stay ahead of potential threats with continuous monitoring and analysis.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Advanced Threat Analytics</Title>
                                    <Paragraph className="card-paragraph">Leverage advanced analytics to accurately detect and assess threats.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Rapid Incident Response</Title>
                                    <Paragraph className="card-paragraph">Implement swift response strategies to mitigate threats and reduce impact.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Comprehensive Reporting</Title>
                                    <Paragraph className="card-paragraph">Receive detailed reports and insights on threat detection and response activities.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <BannerComponent images={bannerImages} />

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Proactive Defense</Title>
                                    <Paragraph className="card-paragraph">Protect your organization with proactive threat detection and response strategies.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Rapid Mitigation</Title>
                                    <Paragraph className="card-paragraph">Quickly mitigate threats and reduce potential impact with our rapid response services.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Insights</Title>
                                    <Paragraph className="card-paragraph">Gain valuable insights into your threat landscape and enhance your security posture.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ThreatDetectionAndResponsePage;

import React from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css';
import './pagestyle.css';
import BannerComponent from '../components/BannerComponent';
import riskAnalysisImage from '../assect/images/banking.jpeg'; // Update path to your image

const { Title, Paragraph } = Typography;

const bannerImages = [
    { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
];

const pageHeaderImages = [
    { url: riskAnalysisImage, title: 'Comprehensive Risk Analysis', subtitle: 'Identify and Mitigate Potential Risks to Your Organization' }
];

const RiskAnalysisPage = () => {
    return (
        <div className="service-page">
            <BannerComponent images={pageHeaderImages} />

            <div className="content-section">
                <Row gutter={[16, 16]} className="even-row" style={{ display: 'flex', alignItems: 'center' }}>
                    <Col xs={24} sm={24} md={16} lg={16}>
                        <Paragraph className="main-paragraph">
                            Effective risk analysis is essential for safeguarding your organization from potential threats. Our Risk Analysis services offer a thorough examination of your business processes, systems, and external factors to identify vulnerabilities and assess potential risks. We provide actionable insights and recommendations to help you implement strategies to mitigate risks and enhance your overall security posture.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div style={{ float: 'right' }}>
                            <img src={riskAnalysisImage} alt="Risk Analysis" className="responsive-img" style={{ width: '100%', height: 'auto' }} />
                        </div>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section section-list">
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Title level={2} className="section-title">Why Choose Our Risk Analysis Services?</Title>
                        <Divider className="section-divider" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card>
                            <ul className="feature-list">
                                <li><RightCircleTwoTone /> <strong>Comprehensive Risk Assessment:</strong> Thoroughly assess risks across all areas of your organization.</li>
                                <li><RightCircleTwoTone /> <strong>Actionable Insights:</strong> Receive practical recommendations to address identified risks and vulnerabilities.</li>
                                <li><RightCircleTwoTone /> <strong>Risk Mitigation Strategies:</strong> Develop and implement strategies to reduce and manage potential risks.</li>
                                <li><RightCircleTwoTone /> <strong>Enhanced Security Posture:</strong> Improve your organization's overall security posture through effective risk management.</li>
                                <li><RightCircleTwoTone /> <strong>Regulatory Compliance:</strong> Ensure compliance with industry regulations and standards through thorough risk analysis.</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Key Features of Our Risk Analysis Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Thorough Risk Assessment</Title>
                                    <Paragraph className="card-paragraph">Conduct a detailed assessment of risks across your organization.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Actionable Recommendations</Title>
                                    <Paragraph className="card-paragraph">Receive practical recommendations to address and mitigate identified risks.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Risk Mitigation Strategies</Title>
                                    <Paragraph className="card-paragraph">Develop strategies to reduce and manage potential risks effectively.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Enhanced Security Posture</Title>
                                    <Paragraph className="card-paragraph">Improve your security posture with effective risk management practices.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <BannerComponent images={bannerImages} />

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Risk Reduction</Title>
                                    <Paragraph className="card-paragraph">Reduce potential risks with our comprehensive risk analysis services.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Proactive Approach</Title>
                                    <Paragraph className="card-paragraph">Adopt a proactive approach to risk management and security.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Analysis</Title>
                                    <Paragraph className="card-paragraph">Benefit from expert analysis and insights to safeguard your organization.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default RiskAnalysisPage;

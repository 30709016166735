// src/components/Logo/Logo.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assect/images/logo.png'; // Adjust the path according to your directory structure

const Logo = () => (
  <div className="logo" style={{ float: 'left' }}>
    <Link to="/">
      <img src={logo} alt="Vaptix Logo" style={{ height: '40px', width: 'auto' }} /> {/* Adjust the size as needed */}
    </Link>
  </div>
);

export default Logo;

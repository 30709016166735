import React from 'react';
import { Row, Col, Card, Typography, Button } from 'antd';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const CareersPage = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Join Our Team</Title>
                <Paragraph className="hero-subtitle">Explore exciting career opportunities</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            Join [Your Company Name] and be part of a dynamic team that drives innovation and excellence. We offer rewarding career opportunities across various disciplines. Browse our current job openings and apply today!
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]}>
                    <Col span={24}>
                        <Title level={2}>Current Job Openings</Title>
                    </Col>
                </Row>
                <Row gutter={[32, 32]}>
                    {/* Example job listings */}
                    <Col xs={24} sm={8}>
                        <Card title="Job Title 1" className="job-card" bordered={false}>
                            <Paragraph>Location: City, Country</Paragraph>
                            <Paragraph>Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi.</Paragraph>
                            <Button type="primary">Apply Now</Button>
                        </Card>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Card title="Job Title 2" className="job-card" bordered={false}>
                            <Paragraph>Location: City, Country</Paragraph>
                            <Paragraph>Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi.</Paragraph>
                            <Button type="primary">Apply Now</Button>
                        </Card>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Card title="Job Title 3" className="job-card" bordered={false}>
                            <Paragraph>Location: City, Country</Paragraph>
                            <Paragraph>Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi.</Paragraph>
                            <Button type="primary">Apply Now</Button>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Title level={2}>How to Apply</Title>
                        <Paragraph>
                            Interested candidates should submit their resume and cover letter to [Email Address]. Please specify the position you are applying for in the subject line of your email.
                        </Paragraph>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default CareersPage;

import React from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles
import BannerComponent from '../components/BannerComponent';
import networkSecurityImage from '../assect/images/elearning.jpeg';

const { Title, Paragraph } = Typography;

const bannerImages = [
    { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
];

const pageHeaderImages = [
    { url: networkSecurityImage, title: 'Comprehensive Network Security: Safeguard Your Digital Infrastructure', subtitle: 'Advanced Protection for Your Network' }
];

const NetworkSecurityPage = () => {
    return (
        <div className="service-page">
            <BannerComponent images={pageHeaderImages} />

            <div className="content-section">
                <Row gutter={[16, 16]} className="even-row" style={ {display: 'flex',
        alignItems: 'center',}}>
                    <Col xs={24} sm={24} md={16} lg={16}>
                        <Paragraph className="main-paragraph">
                            In today's digital age, network security is paramount to protect your valuable data and infrastructure. At Vaptix, we provide state-of-the-art network security solutions tailored to your specific needs. Our services include advanced threat detection, robust firewall protection, and continuous monitoring to ensure your network remains secure from cyber threats.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div style={{float: 'right'}}><img src={networkSecurityImage} alt="Security Monitoring" className="responsive-img" style={{ width: '100%', height: 'auto' }} /></div>
                        
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section section-list">
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Title level={2} className="section-title">Why Choose Our Network Security Solutions?</Title>
                        <Divider className="section-divider" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card>
                            <ul className="feature-list">
                                <li><RightCircleTwoTone /> <strong>Advanced Threat Detection:</strong> Cutting-edge technology to identify and respond to network threats in real-time.</li>
                                <li><RightCircleTwoTone /> <strong>Robust Firewall Protection:</strong> Implementing firewalls to block unauthorized access and ensure secure communication.</li>
                                <li><RightCircleTwoTone /> <strong>Continuous Monitoring:</strong> 24/7 surveillance to detect and address potential security incidents promptly.</li>
                                <li><RightCircleTwoTone /> <strong>Customizable Security Plans:</strong> Tailored solutions to meet your unique network security needs and business requirements.</li>
                                <li><RightCircleTwoTone /> <strong>Expert Support:</strong> Access to our team of security professionals for guidance and rapid response to incidents.</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Key Features of Our Network Security Solutions</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Real-Time Threat Alerts</Title>
                                    <Paragraph className="card-paragraph">Receive instant notifications about any suspicious network activities to stay informed and responsive.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Advanced Firewall Solutions</Title>
                                    <Paragraph className="card-paragraph">Implement state-of-the-art firewalls to protect your network from unauthorized access and attacks.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">24/7 Network Surveillance</Title>
                                    <Paragraph className="card-paragraph">Our team provides around-the-clock monitoring to ensure the security and integrity of your network.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Customizable Security Measures</Title>
                                    <Paragraph className="card-paragraph">Tailor our security solutions to fit the specific needs and challenges of your network environment.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <BannerComponent images={bannerImages} />

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title">Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Network Security</Title>
                                    <Paragraph className="card-paragraph">Strengthen your network's defenses against cyber threats with our advanced security measures.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Cost Savings</Title>
                                    <Paragraph className="card-paragraph">Reduce potential losses and downtime with proactive network security solutions.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Support</Title>
                                    <Paragraph className="card-paragraph">Access to our team of network security experts for ongoing support and incident response.</Paragraph>
                                </Card>
                            </Col>
                           
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default NetworkSecurityPage;

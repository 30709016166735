import React from 'react';
import { Link } from 'react-router-dom';
import { Card as AntdCard, Button } from 'antd';

const { Meta } = AntdCard;

const Card = ({ title, description, img, link }) => {
    return (
        <AntdCard
            hoverable
            cover={<img alt={title} src={img}  />}
            style={{ display: 'flex', flexDirection: 'column' }} // Align items vertically
        >
            <Meta title={title} description={description} />
            <div style={{ marginTop: 'auto' }}> {/* Push the button to the bottom */}
                <Link to={link}>
                    <Button type="primary" style={{ borderRadius: '8px',marginTop: '30px', fontWeight: 'bold' }}>
                        Know More
                    </Button>
                </Link>
            </div>
        </AntdCard>
    );
};

export default Card;

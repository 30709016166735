import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const IndustriesPage = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Industries We Serve</Title>
                <Paragraph className="hero-subtitle">Tailored solutions for various sectors</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="even-row">
                    <Col span={24}>
                        <Paragraph>
                            At [Your Company Name], we provide specialized services tailored to meet the unique challenges of different industries. Explore how we can help your sector thrive with our innovative solutions.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]}>
                    <Col span={8}>
                        <Card bordered={false} className="service-card">
                            <Title level={3}>Healthcare</Title>
                            <Paragraph>Transforming healthcare delivery with advanced technology and patient-centric solutions.</Paragraph>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false} className="service-card">
                            <Title level={3}>Finance</Title>
                            <Paragraph>Enhancing financial operations with secure and scalable solutions for banking and finance.</Paragraph>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false} className="service-card">
                            <Title level={3}>Education</Title>
                            <Paragraph>Empowering educators and students with digital tools and personalized learning experiences.</Paragraph>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="even-row">
                    <Col span={8}>
                        <Card bordered={false} className="service-card">
                            <Title level={3}>Retail</Title>
                            <Paragraph>Driving retail growth through omnichannel strategies and customer engagement solutions.</Paragraph>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false} className="service-card">
                            <Title level={3}>Manufacturing</Title>
                            <Paragraph>Optimizing manufacturing processes with IoT, AI, and predictive analytics for improved efficiency.</Paragraph>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false} className="service-card">
                            <Title level={3}>Government</Title>
                            <Paragraph>Supporting public sector initiatives with secure and scalable technology solutions.</Paragraph>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default IndustriesPage;

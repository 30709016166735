import React from 'react';
import { Row, Col, Card, Typography, Divider } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles
import BannerComponent from '../components/BannerComponent';
import endpointProtectionImage from '../assect/images/elearning.jpeg'; // Update path to your endpoint protection image

const { Title, Paragraph } = Typography;

const bannerImages = [
    { url: 'https://vaptix.com/assets/images/vm.jpg', title: 'Get in Touch with Us Today', subtitle: 'Contact us for more information on our services' }
];

const pageHeaderImages = [
    { url: endpointProtectionImage, title: 'Comprehensive Endpoint Protection: Safeguard Every Device', subtitle: 'Advanced Security for Your Endpoints' }
];

const EndpointProtectionPage = () => {
    return (
        <div className="service-page">
            <BannerComponent images={pageHeaderImages} />

            <div className="content-section">
                <Row gutter={[16, 16]} className="even-row" style={ {display: 'flex',
        alignItems: 'center',}}>
                    <Col xs={24} sm={24} md={16} lg={16}>
                        <Paragraph className="main-paragraph" style={ {display: 'flex !important',
        alignItems: 'center !important'}}>
                            With the increasing number of endpoints in the modern workplace, securing each device is critical to protect your organization's data. At Vaptix, we offer robust endpoint protection solutions designed to defend against malware, ransomware, and other cyber threats. Our services include real-time threat detection, automated response, and comprehensive endpoint management to ensure your devices remain secure and compliant.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div style={{float: 'right'}}><img src={endpointProtectionImage} alt="Endpoint Protection" className="responsive-img" style={{ width: '100%', height: 'auto' }} /></div>
                        
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section section-list" >
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Title level={2} className="section-title">Why Choose Our Endpoint Protection Solutions?</Title>
                        <Divider className="section-divider" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card>
                            <ul className="feature-list">
                                <li><RightCircleTwoTone /> <strong>Real-Time Threat Detection:</strong> Advanced technology to detect and respond to threats targeting your endpoints in real-time.</li>
                                <li><RightCircleTwoTone /> <strong>Automated Response:</strong> Immediate action to contain and remediate threats to minimize potential damage.</li>
                                <li><RightCircleTwoTone /> <strong>Comprehensive Management:</strong> Centralized management for all endpoints to streamline security policies and updates.</li>
                                <li><RightCircleTwoTone /> <strong>Advanced Malware Protection:</strong> State-of-the-art protection against various types of malware and ransomware.</li>
                                <li><RightCircleTwoTone /> <strong>Detailed Reporting:</strong> Insightful reports and analytics to monitor endpoint security status and incident history.</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title" >Key Features of Our Endpoint Protection Solutions</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Real-Time Threat Alerts</Title>
                                    <Paragraph className="card-paragraph">Receive instant notifications about any suspicious activities on your endpoints to stay informed and responsive.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Automated Threat Response</Title>
                                    <Paragraph className="card-paragraph">Automate responses to threats to quickly contain and neutralize potential risks.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Advanced Malware Protection</Title>
                                    <Paragraph className="card-paragraph">Deploy cutting-edge protection against malware and ransomware across all devices.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title level={3} className="card-title">Centralized Management</Title>
                                    <Paragraph className="card-paragraph">Manage all endpoints from a single platform for ease of use and consistent security policies.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <BannerComponent images={bannerImages} />

                <Row gutter={[16, 16]} className="feature-section" style={{ padding: '60px 20px', minHeight: '100vh' }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Title level={2} className="section-title main-title" >Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Endpoint Security</Title>
                                    <Paragraph className="card-paragraph">Fortify your endpoints with our advanced protection measures and expert solutions.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Increased Efficiency</Title>
                                    <Paragraph className="card-paragraph">Optimize your endpoint security operations with our streamlined management tools.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Support</Title>
                                    <Paragraph className="card-paragraph">Gain access to our team of experts for ongoing support and rapid response to threats.</Paragraph>
                                </Card>
                            </Col>
                            
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default EndpointProtectionPage;
